import React from 'react'
import { Link } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby'
import { rhythm, scale } from '../utils/typography'

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <h1
          style={{
            ...scale(1.5),
            marginBottom: rhythm(1.5),
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h1>
      )
    } else {
      header = (
        <h3
          style={{
            fontFamily: `Montserrat, sans-serif`,
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h3>
      )
    }
    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        {header}
        {children}
        <StaticQuery
          query={socialQuery}
          render={data => (
            <footer>
              <div style={{ float: 'right' }}>
                <a href="/rss.xml" target="_blank" rel="noopener noreferrer">
                  rss
                </a>
              </div>
              <a
                href={`https://www.twitter.com/${
                  data.site.siteMetadata.social.twitter
                }`}
                target="_blank"
                rel="noopener noreferrer"
              >
                twitter
              </a>{' '}
              &bull;{' '}
              <a
                href={`https://github.com/${
                  data.site.siteMetadata.social.github
                }`}
                target="_blank"
                rel="noopener noreferrer"
              >
                github
              </a>
            </footer>
          )}
        />
      </div>
    )
  }
}

const socialQuery = graphql`
  query SocialQuery {
    site {
      siteMetadata {
        author
        social {
          twitter
          github
        }
      }
    }
  }
`

export default Layout
